import React from 'react'

const WhygravityLeadership = () => {
    return (
        <>
            <div className='WhygravityLeadership-parent'>
                <div className='WhygravityLeadership-absoluteimage'>
                    <img src="/leaderLayer.svg" alt="" />
                </div>
                <div className='WhygravityLeadership-sub-parent'>
                    <div className="row">
                        <div className="col-md-5">
                            <div className='WhygravityLeadership-left'>
                                <div className='WhygravityLeadership-left-sub1'>
                                    <h2>Leadership</h2>
                                    <p>Our team of architects is composed of highly skilled professionals with diverse expertise in architectural design, urban planning, sustainability, and technological innovation. Through collaboration and teamwork, we strive to create unique and innovative designs that are not only functional but also visually appealing and environmentally conscious.</p>
                                </div>
                                <div className='WhygravityLeadership-left-sub2'>
                                    <p>“We all know that home is a feeling. When your home inspires you, motivates you, and fuels your well-being, that's when you know you've found something truly special." </p>
                                    <p>-- Surya NC</p>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-7 col-lg-6">
                            <div className='WhygravityLeadership-right'>
                                <div className="row">
                                    <div className="col-sm-6 col-md-6 col-lg-5">
                                        <div className="card">
                                            <div className='WhygravityLeadership-right-sub1'>
                                                <img src="/leader1.png" alt="" />
                                            </div>
                                            <div className="card-body">
                                                <p>Surya NC</p>
                                                <span> — Chairman & Managing Director</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-5">
                                        <div className="card">
                                            <div className='WhygravityLeadership-right-sub1'>
                                                <img src="/leader2.png" alt="" />
                                            </div>
                                            <div className="card-body">
                                                <p>Namitha Surya</p>
                                                <span> — Director</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default WhygravityLeadership