import React, { useEffect } from 'react'
import WhygravityLeadership from './WhygravityLeadership'
import './whygravity.css'
import AskSection from './AskSection'
import WhygravityBanner from './WhygravityBanner'
import AboutUs from './AboutUs'
import Footer from '../Footer'
import HomeNavbar from '../HomeNavbar'
import RecentProjects from './RecentProjects'
const Whygravity = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
      <div className='WhyGravity-main-page'>
        <HomeNavbar />
        <WhygravityBanner />
        <AboutUs />
        <RecentProjects />
        <WhygravityLeadership />
        <AskSection />
        <Footer />
      </div>
    </>
  )
}

export default Whygravity