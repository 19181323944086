import React from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Link } from 'react-router-dom';

const Blogs = () => {
    const blogsData = [
        {
            title: "HOW PHYSIOLOGICAL ROUTINES INFLUENCE DESIGN",
            description: "HOMES THAT BUILD GOOD HEALTH",
            image: "/Rectangle 35.png",
            date: "28",
            day: "Tue"
        },
        {
            title: "SUSTAINABLE LUXURY LIVING",
            description: "THE RISE OF ECO-FRIENDLY VILLAS IN BANGALORE",
            image: "/Rectangle 36.webp",
            date: "08",
            day: "Mon"
        },
        {
            title: "SUSTAINABLE LIVING MATERIALS THAT EVERYONE IS ROOTING FOR",
            description: "NATURE-INCLUSIVE LIVING FOR A BETTER TOMORROW",
            image: "/Rectangle 37.png",
            date: "26",
            day: "Wed"
        },
        {
            title: "REMARKABLE EVOLUTION OF NORTH BANGALORE",
            description: "THE EPICENTRE OF GROWTH AND INVESTMENT OPPORTUNITY",
            image: "/4.webp",
            date: "24",
            day: "Mon"
        },
        {
            title: "HOW PHYSIOLOGICAL ROUTINES INFLUENCE DESIGN",
            description: "HOMES THAT BUILD GOOD HEALTH",
            image: "/Rectangle 35.png",
            date: "28",
            day: "Tue"
        },
    ];

    return (
        <div className='Blogs-main-parent'>
            <div className="container-xxl">
                <div className='Blogs-sub-parent'>
                    <div className='Blogs-sub-parent-title text-center'>
                        <p>WHAT’S TRENDING</p>
                        <h1>Latest Blogs & Posts</h1>
                    </div>
                    <Swiper
                        modules={[Pagination]}
                        spaceBetween={20}
                        slidesPerView={3}
                        pagination={{ clickable: true }}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 10
                            },
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 20
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 20
                            }
                        }}
                        // onSwiper={(swiper) => console.log(swiper)}
                        // onSlideChange={() => console.log('slide change')}
                    >
                        {blogsData.map((blog, index) => (
                            <SwiperSlide key={index}>
                                <div className='Blog1'>
                                    <div className='Blog1-sub'>
                                        <div className='Blog1-image'>
                                            <img src={blog.image} alt="" />
                                            <div className='Blogs-image-badge'>
                                                <h4>{blog.date} </h4>
                                                <p>{blog.day}</p>
                                            </div>
                                        </div>
                                        <h2>{blog.title.length > 30 ? blog.title.slice(0,30) + "..." : blog.title}</h2>
                                        <p>{blog.description}</p>
                                        <Link to="/blogs">
                                            <div className='Arrow-Image'>
                                                <img src="/Arrow 1.png" alt="" />
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
}

export default Blogs;
