import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "./Components/Homepage";
import './App.css'
import Whygravity from "./Components/Whygravity/Whygravity";
import Projects from "./Components/Projects/Projects";
import Contactus from "./Components/Contactus/Contactus";
import BlogDetails from "./Components/Blogs/BlogDetails";
import PropertyDetails from "./Components/PropertyDetails/PropertyDetails";
import Blogs from "./Components/Blogs/Blogs";
import InvestGravity from "./Components/InvestGravity/InvestGravity";
import FAQ from "./Components/FaqComponents/FAQ";
import OngoingPropertyDetails from "./Components/OngoingProjects/OngoingPropertyDetails";
import UpcomingPropertyDetails from "./Components/UpcommingProjects/UpcomingPropertyDetails";
import ReraDiclaimer from "./Components/AdditionalPages/ReraDisclaimer";
import Disclaimer from "./Components/AdditionalPages/Disclaimer";
import TermsandConditions from "./Components/AdditionalPages/TermsandConditions";
import Privacypolicy from "./Components/AdditionalPages/PrivacyPolicy";
import Careers from "./Components/Careers/Careers";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="" element={<Homepage />} />
          <Route path="/why-gravity" element={<Whygravity />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact-us" element={<Contactus />} />
          <Route path="/blog/:slug" element={<BlogDetails />} />
          <Route path="/property-details/:slug" element={<PropertyDetails />} />
          <Route path="/ongoing-project-details/:slug" element={<OngoingPropertyDetails />} />
          <Route path="/upcoming-project-details/:slug" element={<UpcomingPropertyDetails />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/invest-with-gravity" element={<InvestGravity />} />
          <Route path="/faqs" element={<FAQ />} />
          <Route path="/rera-disclaimer" element={<ReraDiclaimer/>} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/termsandconditions" element={<TermsandConditions />} />
          <Route path="/privacy-policy" element={<Privacypolicy />} />
          <Route path="/careers" element={<Careers />} />
        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;
