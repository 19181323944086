import React from 'react'

const WhyUs = () => {
    return (
        <>
            <div className='WhyUs-main-parent'>
                <div className='WhyUs-sub-parent'>
                    <div className='WhyUs-content'>
                        <div className='WhyUs-content-sub'>
                            <div className='WhyUs-line'></div>
                            <h1>Why Us</h1>
                        </div>
                        <div className='Whyus-desc'>
                            <p className='Whyus-desc-content'>Gravity Home approach is to co-create as we co-exist, nurture community living, providing opportunities for ponder and the joy of personal spaces. Sharing a transparent journey along our course of development, we invite you to Gravity Living, a culture committed to belonging.</p>
                            <div className='WhyUs-content-sub-parent'>
                                <div className='WhyUs-content-sub-parent-sub'>
                                    <div className='WhyUs-content-image'>
                                        <img src="/leaf.svg" alt="" />
                                    </div>
                                    <p>Eco-Friendly & Quality</p>
                                </div>
                                <div className='WhyUs-content-sub-parent-sub'>
                                    <div className='WhyUs-content-image'>
                                        <img src="/settings.svg" alt="" />
                                    </div>
                                    <p>Innovative & Modern Design</p>
                                </div>
                                <div className='WhyUs-content-sub-parent-sub'>
                                    <div className='WhyUs-content-image'>
                                        <img src="/clock.svg" alt="" />
                                    </div>
                                    <p>Community & Amenities</p>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className='Whyus-absoluteSection'>
                        <p>“ A home is where our stories begin, and with time, these moments shape our world. The very fabric of belonging, our homes inspire. Designing a home then, is not just resonating with your idea of living, it is supporting your culture of living where your values and your narratives contribute to your experiences. “</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhyUs