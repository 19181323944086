import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const ContactFormModal = ({ isModalOpen, setIsModalOpen, handleOk, handleCancel, }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        phonenumber: '',
        message: ''
    });

    const [errors, setErrors] = useState({});
    const [modalMessage, setModalMessage] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        const errors = {};
        if (!formData.name.trim()) errors.name = 'Name is required';
        if (!formData.email.trim()) errors.email = 'Email is required';
        if (!/\S+@\S+\.\S+/.test(formData.email)) errors.email = 'Email is invalid';
        if (!formData.subject.trim()) errors.subject = 'Subject is required';
        if (!formData.phone.trim()) errors.phone = 'Phone number is required';
        if (!/^\d{10}$/.test(formData.phone)) errors.phone = 'Phone number is invalid';
        return errors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            setModalMessage('Your message has been sent successfully!');
        } else {
            setModalMessage('Please enter valid details');
        }
        setErrors(formErrors);
        setIsModalOpen(true);
    };

    useEffect(() => {
        const currentUrl = window.location.href;
        const urlInput = document.getElementById('currentUrlInput');
        if (urlInput) {
            urlInput.value = currentUrl;
        }
    }, [isModalOpen]);


    return (
        <Modal footer={null} title="Let's Talk to Our Experts" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <form action="http://localhost/test/contact.php" method='post'>
                <div className='ContactForm-sub-right'>
                    <div className="row">
                        <div className="col-md-6">
                            <input
                                type="text"
                                name="name"
                                placeholder='Name *'
                                value={formData.name}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.name && <p className="error text-danger">{errors.name}</p>}
                        </div>
                        <div className="col-md-6">
                            <input
                                type="email"
                                name="email"
                                placeholder='Email *'
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.email && <p className="error text-danger">{errors.email}</p>}
                        </div>
                        <div className="col-md-6">
                            <input
                                type="text"
                                name="subject"
                                placeholder='City'
                                value={formData.subject}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.subject && <p className="error text-danger">{errors.subject}</p>}
                        </div>
                        <div className="col-md-6">
                            <input
                                type="tel"
                                name="phonenumber"
                                placeholder='Phone *'
                                value={formData.phonenumber}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.phone && <p className="error text-danger">{errors.phone}</p>}
                        </div>
                        <div className="col-md-12">
                            <textarea
                                name="message"
                                placeholder='Hello I am interested in...'
                                value={formData.message}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <input type="hidden" id="currentUrlInput" name="currentUrl" />
                        <div className='Sendnowbutton'>
                            <button type='submit' name='submit'>Send Now ➜ </button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
    )
}

export default ContactFormModal