import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ContactFormModal from '../Contactus/ContactFormModal';
const WhygravityBanner = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <div className='WhygravityBanner-parent'>
                <div className='banner-background-image'>
                    <img src="/aboutusbanner.svg" alt="" />
                </div>
                <div className='WhygravityBanner-sub-parent'>
                    <div className="row banner-mainrow">
                        <div className='BannerAbsoluteSection'>
                            <p>Known For</p>
                            <div className='BannerAbsoluteSection-sub'>
                                <span>Quality & Experience</span>
                                <hr />
                                <span>Discover Your Dream Home</span>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="WhygravityBanner-left">
                                <h1>Innovative Home Solutions for Every Vision</h1>
                                <div className='banner-icon-parent'>
                                    <div className='banner-icon'>
                                        <Link to="#" role='button' onClick={showModal}>
                                            <img src="/Button CTA.png" alt="" />
                                        </Link>

                                    </div>
                                    <p>Explore the symbiotic relationship between construction and the environment</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className='Banner-mainimage'>
                                <img src="/whygravitybanner.png" alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <ContactFormModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />
        </>
    )
}

export default WhygravityBanner