import React, { useRef, useState } from 'react';
import './swiper.css';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation } from 'swiper/modules';
import { Modal } from 'antd';
import ContactFormModal from './Contactus/ContactFormModal';
const HomeProjects = () => {
    const categoryContainerRef = useRef(null);
    const [swiperInstance, setSwiperInstance] = useState(null);

    const handleLeftArrow = () => {
        if (swiperInstance) {
            swiperInstance.slidePrev();
        }
    };

    const handleRightArrow = () => {
        if (swiperInstance) {
            swiperInstance.slideNext();
        }
    };

    const carouselImages = [
        {
            image: "/ProjectImages.png",
            project: "Gravity Smera Gardens",
            link: "/ongoing-project-details/gravity-smera-gardens"
        },
        {
            image: "/grvityaranya.webp",
            project: "Gravity Aranya",
            link: "/ongoing-project-details/gravity-aranya"
        },
        {
            image: "/skyforest.webp",
            project: "Gravity Sky Forest",
            link: "/ongoing-project-details/gravity-sky-forest"
        },
        {
            image: "/lakeestate.webp",
            project: "Gravity Lake Estates",
            link: "/ongoing-project-details/gravity-lake-estates"
        },
        {
            image: "/forestview2.webp",
            project: "Gravity Forest View",
            link:"http://localhost:3000/property-details/gravity-forest-view"
        },
        {
            image: "/manaasanilaya2.webp",
            project: "Gravity Maanasa Nilayam",
            link:"/property-details/gravity-manaasa-nilayam"
        },
        {
            image: "/ProjectImages/rahanahalli1.webp",
            project: "Gravity Rachenahalli",
            link:"/upcoming-project-details/gravity-racchena-halli"
        },

    ];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <div className='HomeProjects-parent' style={{ backgroundImage: `url('/Sectionbg.png')`, height: "fit-content", backgroundSize: "cover" }}>
                <div className="container-fluid">
                    <div className="HomeAboutus-sub-parent">
                        <div>
                            <div className='SwiperSlide-projects-parent'>
                                <div className='d-flex justify-content-between align-items-center pb-5 circlesvg-image'>
                                    <div className='HomeAboutus-title-sub align-items-start'>
                                        <div className='HomeAboutus-image'>
                                            <img src="/circlewhite.svg" alt="" />
                                        </div>
                                        <h2 className='text-white mt-3'>The Projects </h2>
                                    </div>
                                    <p className='w-25 text-white m-0'>Together, we can conquer challenges, utilize our strengths, and achieve remarkable success in this ambitious home project.</p>
                                </div>
                            </div>
                            <div className='SwiperSection'>
                                <Swiper
                                    onSwiper={setSwiperInstance}
                                    modules={[Navigation]}
                                    loop={true}
                                    className="mySwiper"

                                >
                                    {carouselImages.map((item, index) => (

                                        <SwiperSlide key={index}>
                                            <div className='Projects-swiper-image'>
                                                <Link to={item.link}>
                                                    <img src={item.image} alt="" />
                                                </Link>
                                            </div>
                                            <div className='gravitySwiper-title'>
                                                <Link to={item.link} style={{ color: 'white'}}>
                                                <h2>{item.project} </h2>
                                                </Link>
                                            </div>
                                        </SwiperSlide>

                                    ))}
                                </Swiper>

                                <div className='Testimonial-PaginationButtons d-flex justify-content-center align-items-center gap-5 mt-4 w-100'>
                                    <div onClick={handleLeftArrow}>
                                        <i className="fa-solid fa-arrow-left"></i>
                                    </div>
                                    <div onClick={handleRightArrow}>
                                        <i className="fa-solid fa-arrow-right"></i>
                                    </div>
                                    <Link to="/projects" className='Exploreall-button'><button>Explore All Projects...</button> </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='OurQuality-mainparent'>
                <div className='OurQuality-mainparent-sub' style={{ backgroundImage: `url('/Sectionbg.png')`, height: "fit-content", backgroundSize: "cover" }}>
                    <div className='container-fluid'>
                        <div className="HomeAboutus-sub-parent">
                            <div className='d-flex justify-content-between circlesvg-image'>
                                <div className='HomeAboutus-title-sub '>
                                    <div className='HomeAboutus-image'>
                                        <img src="/circlewhite.svg" alt="" />
                                    </div>
                                    <h2 className='text-white'>Our Quality</h2>
                                </div>
                            </div>
                            <div className='OurQuality-parent'>
                                <div className="row justify-content-between position-relative">
                                    <div className="col-md-5">
                                        <div className='OurQuality-left'>
                                            <h1 className='text-white'>DESIGN A COZY AND FRESH HOMES.</h1>
                                            <p>Crafting an Inviting Haven: Unveiling the Art of Designing a Cozy and Fresh homes for Unmatched Comfort and Serenity</p>
                                            <Link to="#" onClick={showModal}>
                                                <button>Get Started</button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className='OurQuality-right d-flex'>
                                            <div className='OurQuality-right-image'>
                                                <img src="/ourquality.png" alt="" />
                                                <div className='OurQualituArrow' role='button' onClick={showModal}>
                                                    <img src="/bannerarrow.png" alt="" />
                                                </div>
                                            </div>
                                            <div className='blur-image'>
                                                <img src="/blurimage.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <ContactFormModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />
        </>
    );
};

export default HomeProjects;
