import React, { useEffect } from 'react'
import HomeNavbar from './HomeNavbar'
import "./Home.css"
import HomeBanner from './HomeBanner'
import HomeAboutus from './HomeAboutus'
import HomeProjects from './HomeProjects'
import Blogs from './Blogs'
import OurStrengths from './OurStrengths'
import Footer from './Footer'
import Testimonials from './Testimonials'
const Homepage = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
      },[])
    return (
        <>
            <div className='Homepage-main-parent'>
                {/* <HomeNavbar /> */}
                <HomeBanner/>
                <HomeAboutus/>
                <HomeProjects/>
                <Testimonials/>
                <Blogs/>
                <OurStrengths/>
                <Footer/>
            </div>
        </>

    )
}

export default Homepage