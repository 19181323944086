
import React from 'react'
import HomeNavbar from '../HomeNavbar';
import "../FaqComponents/faq.css";
import Footer from '../Footer';
const Careers = () => {
    return (
        <>
            <div className="Investfaq-parent">
                <div className='Investfaq-subparent'>
                    <HomeNavbar />
                </div>
                <div className='Careerspage-content-main-parent'>
                    <div className='Careerspage-content'>
                        <p>Thank you for your interest in working with us. We are thrilled to meet people who design the difference by contributing to Gravity Homes!</p>
                        <p>
                            You can also reach us at <a href="mailto:hr@gravityhomes.in">hr@gravityhomes.in</a> and connect with our team. We value your interest and hope to potentially welcome you to our team in the future. Have a good day!</p>
                    </div>
                </div>

            </div>
            <Footer />
        </>
    )
}
export default Careers;