import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';
const RecentProjects = () => {
    const swiperRef = useRef(null);

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };
    const sliderImages = [
        {
            image: "/ProjectImages.png",
            title: "Gravity Smera Gardens",
            location: "Attibele Hobli, Anekal Taluk, Bangalore",
            link: "/ongoing-project-details/gravity-smera-gardens"
        },
        {
            image: "/ProjectImages/forestview8.webp",
            title: "Gravity Forest View",
            location: "Banashankari, Bengaluru",
            link: "http://localhost:3000/property-details/gravity-forest-view"
        },
        {
            image: "/ProjectImages/sky_forest1.webp",
            title: "Gravity Sky Forest",
            location: "Thanisandra, North Bengaluru",
            link: "/ongoing-project-details/gravity-sky-forest"
        },
        {
            image: "/lakeestate.webp",
            title: "Gravity Lake Estates",
            location: "Rajanukunte, Yelahanka",
            link: "/ongoing-project-details/gravity-lake-estates"
        },
        {
            image: "/grvityaranya.webp",
            title: "Gravity Aranya",
            location: "Banashankari,Bangalore",
            link: "/ongoing-project-details/gravity-aranya"
        },
        {
            image: "/ProjectImages/rahanahalli1.webp",
            title: "Gravity Racchena Halli",
            location: "Behind Manyata Tech park, Bengaluru",
            link: "/upcoming-project-details/gravity-racchena-halli"
        }
    ]
    return (
        <div className='RecentProjects-parent'>
            <div className='RecentProjects-sub-parent position-relative'>
                <div className='RecentProjects-title d-flex gap-3 align-items-center'>
                    <h2>Recent Projects</h2>
                    <div className="EmptyLine" style={{ width: "200px" }}></div>
                </div>
                <Swiper
                    ref={swiperRef}
                    pagination={{
                        type: 'fraction',
                    }}
                    loop={true}
                    // autoplay={{
                    //     delay: 2500,
                    //     disableOnInteraction: false,
                    // }}
                    navigation={{
                        prevEl: '.swiper-button-prev',
                        nextEl: '.swiper-button-next',
                    }}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                >
                    {
                        sliderImages.length > 0 && sliderImages.map((item, index) => (
                            <SwiperSlide key={index}>
                                <div className='RecentProjects-image'>
                                    <Link to={item.link}>
                                        <img src={item.image} alt="" />
                                    </Link>

                                    <div className='SwiperAbsoluteText'>
                                        <h2>{item.title} </h2>
                                        <div className='location-parent'>
                                            <img src="/map-pin.svg" alt="" />
                                            <span>{item.location} </span>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
                <div className='Swiper-pagination-buttons-parent'>
                    <div className="swiper-button-prev" onClick={goPrev}>
                        <img src="/Left Arrow.png" alt="Previous" />
                    </div>
                    <div className="swiper-button-next" onClick={goNext}>
                        <img src="/Right Arrow.png" alt="Next" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RecentProjects;
