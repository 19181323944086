import React, { useEffect } from 'react'
import './Projects.css'
import ProjectsBanner from './ProjectsBanner'
import OnGoingProjects from './OnGoingProjects'
import UpcomingProjects from './UpcomingProjects'
import PreviousProjects from './PreviousProjects'
import WhyUs from './WhyUs'
import HomeNavbar from '../HomeNavbar'
import Footer from '../Footer'
const Projects = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <div className='Projects-mainpage'>
        <HomeNavbar />
        <ProjectsBanner />
        <OnGoingProjects />
        <UpcomingProjects />
        <WhyUs />
        <PreviousProjects />
        
      </div>
      <Footer />
    </>
  )
}

export default Projects