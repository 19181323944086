import React from 'react';
import { Pagination, A11y,Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Link } from 'react-router-dom';

const TestimonialSwiper2 = () => {
    const blogsData = [{
        text:"What an experience being a customer of Gravity Homes. Managing director, CRM staff all are so friendly and accomodating.  Truly customer centric! Thank you.",
        userImage:"/client.png",
        user:"Sachin P S"
    },
    {
        text:"I'm working with Gravity team as a electrical suply vendor. Very happy the way they treat vendor with promt payments.",
        userImage:"/client.png",
        user:"arif azad"
    },
    {
        text:"Very well executed projects as met builder who is totally involved in the project and found him very passionate about all his projects",
        userImage:"/client.png",
        user:"alka ravikumar"
    }

    ];

    return (
        <>
            <div className='TestimonialSwiper2-mainparent' style={{ height: "400px" }}>
                <div className='TestimonialSwiper2-subparent'>
                    <Swiper
                        modules={[Pagination, A11y,Autoplay]}
                        spaceBetween={20}
                        slidesPerView={1}
                        loop={true}
                        autoplay = {{
                            duration:2500,
                            disableOnInteraction:false
                        }}
                        direction={'vertical'}
                        pagination={{ clickable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                    >
                        <br /><br />
                        {blogsData.map((blog, index) => (
                            <SwiperSlide key={index}>
                                <div className='TestimonialSwiper2-card'>
                                    <div className="card">
                                        <div className='TestimonialAbsolute'>
                                            <img src="/testimonial.svg" alt="" />
                                        </div>
                                        <div className='TestimonialSwiper2-badge'>
                                            <img src="/testimonialbadge.png" alt="" />
                                        </div>
                                        <p>{blog.text} </p>
                                        <hr />
                                        <div className='Testimonial-card-footer'>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <div className='testimonial-client-image'>
                                                    <img src={blog.userImage} alt="" />
                                                </div>
                                                <p className='m-0'>{blog.user} </p>
                                            </div>
                                            <div className='Testimonial-Ratings'>
                                                <i class="fa-solid fa-star" style={{ color: "#FFC700" }} ></i>
                                                <i class="fa-solid fa-star" style={{ color: "#FFC700" }}></i>
                                                <i class="fa-solid fa-star" style={{ color: "#FFC700" }}></i>
                                                <i class="fa-solid fa-star" style={{ color: "#FFC700" }}></i>
                                                <i class="fa-solid fa-star" style={{ color: "#FFC700" }}></i>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

            </div>

        </>
    );
};

export default TestimonialSwiper2;
